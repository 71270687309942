import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIconnew.png";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
// import EyeIcon from "../assets/svg/EyeIcon";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/mmplay.png";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import MenuIcon from "../assets/images/menu-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import BottomHead from "./BottomHead";
import ModalWrapper from "./ModalWrapper";

import { getNotifications } from "../redux/app/actions";

const Header = ({ agentCode = "" }) => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleShow1 = () => setShow1(!show1);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  useEffect(() => {
    if (show1) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
  }, [show1]);

  useEffect(() => {
    if (agentCode) {
      setShowAuthModals(true);
      setDefaultModal(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
    }
  }, [agentCode]);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  const htmlElement = document.documentElement;

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}

      <header className="header header_bfrlgn">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                >
                  <img src={MenuIcon} alt="User Menu Icon" width={25} />
                </Button>
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
                <div className="header-left ms-xl-4 d-none d-lg-block">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="d-none"
                  />
                  <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                    <Nav className="me-auto">
                      {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                      <Nav.Link href="/spmsports/cricket">Exchange</Nav.Link>
                      <Nav.Link href="/betby">Sports</Nav.Link>
                      <Nav.Link href="/live-dealer">Live Dealers</Nav.Link>
                      <Nav.Link href="/casino/evolution">Game Shows</Nav.Link>
                      <Nav.Link href="/casino/spribe/aviator">Aviator</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </div>
                <div className="headerRight ms-auto">
                  <ul className="d-none">
                    <li className="">
                      <a
                        className="tg"
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "#"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={TelegramIcon} alt="Telegram Icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="wtp"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon} alt="Whatsapp Icon" />
                      </a>
                    </li>
                    {/* <li className="theme-btn">
                      <buton
                        type="button"
                        className="btn"
                        onClick={toggleClass}
                      >
                        <img
                          src={MoonLight}
                          alt="moon"
                          className="dark_theme"
                        />
                        <img src={SunLight} alt="sun" className="light_theme" />
                      </buton>
                    </li> */}
                  </ul>

                  <Button
                    variant="primary"
                    // onClick={() => {
                    //   navigate("/sign-in");
                    // }}
                    onClick={() => {
                      isMobile
                        ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        : navigate("/sign-in");
                    }}
                    className="login_btn me-2"
                  >
                    Log In
                  </Button>
                  <Button
                    variant="primary"
                    // onClick={() => {
                    //   navigate("/mobile-number");
                    // }}

                    onClick={() => {
                      isMobile
                        ? handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
                        : navigate("/mobile-number");
                    }}
                    className="signup_btn"
                  >
                    Sign Up
                  </Button>
                  <ul className="" style={{ marginLeft: "5px" }}>
                    <li>
                      <GamesSearchModal />
                    </li>
                  </ul>
                  {/* <div className="searchbar">
                  <AiOutlineSearch />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
            agentCode={agentCode}
          />
        )}
        {show1 && (
          <MobLeftbar
            className="d-block d-md-none"
            setShowAuthModals={setShowAuthModals}
          />
        )}
      </header>
      <BottomHead />
    </>
  );
};

export default Header;
